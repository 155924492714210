import React, { useRef, useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";
import "./Map.css";
import { createRoot } from "react-dom/client";
import PlaceIcon from "@mui/icons-material/Place";
import { Attribute, AttributeLabel, AttributeValue } from "../../Attribute";
import { Box } from "@mui/material";
import { calculateDistance } from "../../../utils/utility";
mapboxgl.accessToken = process.env.NEXT_PUBLIC_REACT_APP_MAPBOX_ACCESS_TOKEN;

const defaultPosition = {
  lng: -73.22082853735371,
  lat: 40.809947662368856,
};

const venueAttributes = [
  {
    label: "Name",
    name: "venue_name",
  },
  {
    label: "Address",
    name: "address1",
  },
  {
    label: "City",
    name: "venue_city",
  },
  {
    label: "State",
    name: "venue_state",
  },
  {
    label: "Zip Code",
    name: "VENUE_ZIP",
  },
];
const Marker = ({ onClick, children, feature }) => {
  const _onClick = () => {
    onClick(feature);
  };
  return (
    <PlaceIcon sx={{ color: "red" }} color="red" onClick={_onClick}>
      {children}
    </PlaceIcon>
  );
};
const MyGoogleMap = ({
  markers,
  centerProp,
  showDistance = true,
  height = "400px",
  eventView = false,
}) => {
  const mapContainerRef = useRef(null);
  const [center, setCenter] = useState(centerProp || defaultPosition);
  const [selectedMarker, setSelectedMarker] = useState(
    markers.filter((m) => !isNaN(m?.lat) && !isNaN(m?.lon))[0]
  );
  const markerClicked = (e) => {
    setSelectedMarker(e);
  };
  // Initialize map when component mounts
  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: { lng: selectedMarker?.lon, lat: selectedMarker?.lat }, // Deliberately changing to venues lon lat
      zoom: 12,
    });
    // Wait for the style to fully load

    map.on("style.load", () => {
      // ✅ Add layers and sources inside here
      if (eventView) {
        const geojsonFeatures = markers
          .filter((m) => !isNaN(m?.lat) && !isNaN(m?.lon))
          .map((m) => ({
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [m.lon, m.lat],
            },
            properties: {
              label: `${m.title || ""} @ ${m.venue_name || ""}`,
            },
          }));

        map.addSource("event-labels", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: geojsonFeatures,
          },
        });

        map.addLayer({
          id: "event-label-layer",
          type: "symbol",
          source: "event-labels",
          minzoom: 13,
          layout: {
            "text-field": ["get", "label"],
            "text-size": 14,
            "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
            "text-offset": [0, 1.2],
            "text-anchor": "top",
          },
          paint: {
            "text-color": "#000",
            "text-halo-color": "#fff",
            "text-halo-width": 1.5,
          },
        });
      }
    });
    map.addControl(new mapboxgl.NavigationControl(), "top-right");

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const currentPosition = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setCenter(currentPosition);
        },
        () => {
          // If there's an error or the user denies location access, use the default position
          setCenter(defaultPosition);
        }
      );
    } else {
      // Geolocation is not supported by this browser
      setCenter(defaultPosition);
    }

    markers
      .filter((m) => !isNaN(m?.lat) && !isNaN(m?.lon))
      .forEach((feature) => {
        const ref = React.createRef();
        ref.current = document.createElement("div");

        createRoot(ref.current).render(
          <Marker onClick={markerClicked} feature={feature} />
        );

        // Get all markers with the same coordinates
        const sameMarkers = markers.filter(
          (m) => m.lat === feature.lat && m.lon === feature.lon
        );

        let offset = [0, 0]; // Default offset

        // If there is more than one marker with the same coordinates
        if (sameMarkers.length > 1) {
          const index = sameMarkers.indexOf(feature);
          offset = [16 * (index - (sameMarkers.length - 1) / 2), 0]; // Calculate offset
        }
        const band = feature?.bands?.[0];
        const bandImage = band?.imageUrl || band?.img_preview || null;

        // Create the Mapbox Marker and apply the offsety
        new mapboxgl.Marker(ref.current)
          .setLngLat({ lng: feature?.lon, lat: feature?.lat })
          .setOffset(offset) // Set offset for overlapping markers
          .setPopup(
            new mapboxgl.Popup({ offset: 25 }).setHTML(
              eventView
                ? `<div>
                  <div style="font-size: 16px; font-weight: bold; color: #fff; margin-bottom: 8px; background:#334e65; padding:8px">
                     <a href="/events?eventId=${
                       feature?.id
                     }"style="color: white;" style="color: white;">
                       ${feature?.title || feature?.venue_name}</a>
                  </div>
                    ${
                      bandImage
                        ? `<div style="text-align:center; margin-bottom: 8px;">
                            <img src="${bandImage}" alt="${band?.bandname}"
                            style="width: 80px; height: 80px; object-fit: cover; border-radius: 8px; border: 1px solid #ccc;"
                      />
                          </div>`
                        : ""
                    }
                  <div style="color: #555; margin-bottom: 4px; padding:0px 8px">
                    <strong>Venue: </strong>
                    <a href="/venues/${
                      feature?.venue_internallink
                    }" style="color: #1976d2; text-decoration: underline;">${
                    feature?.venue_name
                  }</a>
                  </div>
                  <div style="color: #555; margin-bottom: 4px; padding:0px 8px">
                    <strong>Address:</strong> ${feature?.address1}, ${
                    feature?.venue_city
                  }, ${feature?.venue_state}, ${feature?.VENUE_ZIP}
                  </div>
                  <div style="color: #555; padding:0px 8px 16px 8px">
                    <strong>Date:</strong> ${
                      feature?.main_date || feature?.tzone
                    }
                  </div>
                </div>`
                : `<h3 style="margin-bottom: 4px"><a href="/venues/${
                    feature?.internalLink || feature?.id
                  }" style="color: #1976d2; text-decoration: underline;">${
                    feature?.venue_name
                  }</a></h3>
         <p>${feature?.description || ""}</p>`
            )
          )
          .addTo(map);
      });

    // Add navigation control (the +/- zoom buttons)
    map.addControl(new mapboxgl.NavigationControl(), "top-right");

    // Clean up on unmount
    return () => map.remove();
  }, [markers]);

  return (
    <div>
      {showDistance && (
        <Box>
          <Attribute>
            <AttributeLabel>Venue Zip Code</AttributeLabel>
            <AttributeValue>{selectedMarker?.VENUE_ZIP}</AttributeValue>
          </Attribute>
          <Attribute>
            <AttributeLabel>Address</AttributeLabel>
            <AttributeValue>
              {selectedMarker?.address1} {selectedMarker?.venue_city},{" "}
              {selectedMarker?.venue_state}
            </AttributeValue>
          </Attribute>
          <Attribute>
            <AttributeLabel>Your Coordinates</AttributeLabel>
            <AttributeValue>
              {`lng: ${center?.lng}, lat: ${center?.lat}`}
            </AttributeValue>
          </Attribute>
          <Attribute>
            <AttributeLabel>Selected Venue</AttributeLabel>
            <AttributeValue>
              {`lng: ${selectedMarker?.lon}, lat: ${selectedMarker?.lat}`}
            </AttributeValue>
          </Attribute>
          <Attribute>
            <AttributeLabel>As the Crow Flies distance</AttributeLabel>
            <AttributeValue>
              {calculateDistance(
                center?.lat,
                center?.lng,
                selectedMarker?.lat,
                selectedMarker?.lon
              ) + " mi"}
            </AttributeValue>
          </Attribute>
          <Attribute>
            <AttributeLabel>Open in Maps</AttributeLabel>
            <AttributeValue>
              {/* <a href={`https://www.google.com/maps/place/${encodeURIComponent(selectedMarker?.address1)}+${encodeURIComponent(selectedMarker?.venue_city)},+${encodeURIComponent(selectedMarker?.venue_state)}+${encodeURIComponent(selectedMarker?.VENUE_ZIP)}`}
          target="_blank"
          rel="noopener noreferrer"
        ><img src="/googlemap.jpg" alt="Google Map" style={{ width: '24px', marginRight: '10px' }} /></a> */}
              <a
                href={`http://maps.apple.com/?q=${encodeURIComponent(
                  selectedMarker?.address1
                )}+${encodeURIComponent(
                  selectedMarker?.venue_city
                )},+${encodeURIComponent(
                  selectedMarker?.venue_state
                )}+${encodeURIComponent(selectedMarker?.VENUE_ZIP)}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="/applemap.jpg"
                  alt="Apple/Google Map"
                  style={{ width: "12px", marginRight: "10px" }}
                />
              </a>
            </AttributeValue>
          </Attribute>
        </Box>
      )}
      <div
        style={{ height: height }}
        className="map-container"
        ref={mapContainerRef}
      />
    </div>
  );
};

export default MyGoogleMap;
