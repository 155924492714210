"use client";

import React, { useEffect, useMemo, useState } from "react";
import Head from "next/head";
import { Box, Typography } from "@mui/material";

import EventAccordion from "@/components/Core/Events/EventAccordion";
import { useRouter, useParams, useSearchParams } from "next/navigation";
import { useFetchEvents, useFetchPageByInternalLink } from "@/hooks";
import Weather from "@/components/Weather";
import { notFound } from "next/navigation";

const attributes = [
  {
    label: "Title",
    name: "title",
    sortable: true,
  },
  {
    label: "Description",
    name: "eventdescription",
    component: {
      type: "html",
    },
  },
  {
    label: "Event Date",
    name: "main_date",
    sortable: true,
    component: {
      type: "dateTime",
    },
  },
  {
    label: "Bands",
    name: "bands",
    state: "bands",
    component: {
      type: "array",
      contextProp: "bandname",
      queryParam: "bandId",
    },
  },
  {
    label: "Venue",
    name: "venue",
    state: "venues",
    component: {
      type: "object",
      contextProp: "venue_name",
      queryParam: "venueId",
    },
  },
];

export default function DynamicEvents() {
  const [open, setOpen] = useState(false);
  const searchParams = useSearchParams();
  const router = useRouter();
  const eventId = searchParams.get("eventId");
  const location = searchParams.get("location");
  const { wildcard } = useParams();

  const {
    page,
    loading: pageLoading,
    error,
  } = useFetchPageByInternalLink(removeTrailingSlash(wildcard));

  function removeTrailingSlash(str) {
    return str.endsWith("/") ? str.slice(0, -1) : str;
  }

  if (error) {
    return notFound();
  }

  const [paginationProps, setPaginationProps] = useState({ skip: 0, take: 20 });
  const [localPagination, setLocalPagination] = useState({ skip: 0, take: 20 });
  const [currentPage, setCurrentPage] = useState(0);

  const memoizedFilters = useMemo(() => {
    return [
      {
        field: "coordinates",
        operator: "n/a",
        value: [
          {
            miles: page?.miles,
            longitude: parseFloat(page?.lon),
            latitude: parseFloat(page?.lat),
          },
        ],
      },
    ];
  }, [page]);

  const { events, loading, totalItems, isPaginated } = useFetchEvents(
    paginationProps.take,
    paginationProps.skip,
    memoizedFilters,
    Boolean(page?.miles)
  );

  const removeQueryParam = (paramToRemove) => {
    const params = new URLSearchParams(searchParams.toString());
    params.delete(paramToRemove);
    router.push(`${window.location.pathname}?${params.toString()}`);
  };

  const getFilteredResults = (skip, take) => {
    if (isPaginated) return events;
    return events.slice(
      localPagination.skip,
      localPagination.take + localPagination.skip - 1
    );
  };

  return (
    <div style={{ padding: 30 }}>
      <Box>
        {/* Head Section for SEO */}
        <Head>
          <title>{page?.title || "Dynamic Events"}</title>
          <meta
            name="description"
            content={page?.description || "Explore events"}
          />
          <meta name="keywords" content={page?.title || ""} />
          <meta property="og:title" content={page?.title || "Dynamic Events"} />
          <meta
            property="og:description"
            content={page?.description || "Explore events"}
          />
        </Head>

        <Box sx={{ width: "100%", textAlign: "center", mb: 2 }}>
          <h1>{page?.title}</h1>
          <p>{page?.description}</p>
          {page?.lat && page?.lon && (
            <Weather lat={page?.lat} lon={page?.lon} />
          )}
        </Box>

        <Box
          sx={{
            display: "flex",
            width: "100%",
            gap: { xs: "10px", sm: "30px" },
            flexDirection: { xs: "column-reverse", sm: "row" },
          }}
        ></Box>

        {events && (
          <EventAccordion
            loading={loading || pageLoading}
            totalCount={totalItems}
            setPaginationProps={(skip, take) => {
              if (isPaginated) {
                setPaginationProps({ skip, take });
              } else {
                setLocalPagination({ skip, take });
              }
            }}
            currentPage={currentPage}
            setCurrentPage={(v) => setCurrentPage(v)}
            data={getFilteredResults()}
            attributes={attributes}
          />
        )}

        {events?.length === 0 && !loading && (
          <Typography
            sx={{
              justifyContent: "center",
              display: "flex",
            }}
          >
            No Data found
          </Typography>
        )}

        {eventId && (
          <EventDetails
            event={events.find((e) => e.id == eventId)}
            onDrawerClose={() => {
              removeQueryParam("eventId");
            }}
          />
        )}
      </Box>
    </div>
  );
}
