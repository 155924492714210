import React from "react";
import moment from "moment";

const DateTimeDisplay = ({ isoDate, compactDesign }) => {
  const date = moment(isoDate);
  const dayOfMonth = date.format("DD");
  const month = date.format("MMM");
  const year = date.format("YYYY");
  const dayOfWeek = date.format("ddd");
  const time = date.format("hh:mm A");

  return (
    <>
      {!compactDesign ? (
        <div style={{ textAlign: "center", fontFamily: "Arial, sans-serif" }}>
          <div style={{ fontSize: "13px", color: "#636363" }}>{dayOfWeek}</div>
          <div
            style={{ fontSize: "24px", fontWeight: "bold", color: "#fd6208" }}
          >
            {dayOfMonth}
          </div>
          <div style={{ fontSize: "14px", fontWeight: "bold" }}>
            {month}, {year}
          </div>
          <div style={{ fontSize: "14px", color: "#636363" }}>{time}</div>
        </div>
      ) : (
        <div style={{ textAlign: "center", fontFamily: "Arial, sans-serif" }}>
          <div style={{ fontSize: "13px", color: "#636363" }}></div>

          <div style={{ fontSize: "14px", color: "#fd6208" }}>
            {dayOfMonth} {month}, {year}
          </div>
          <div style={{ fontSize: "12px", color: "#636363" }}>
            {dayOfWeek} {time}
          </div>
        </div>
      )}
    </>
  );
};

export default DateTimeDisplay;
