import React, { useState, useEffect } from "react";
import { Box, Typography, Grid } from "@mui/material";

const Weather = ({ lat, lon }) => {
  const [weatherData, setWeatherData] = useState(null);

  useEffect(() => {
    const fetchWeather = async () => {
      try {
        const response = await fetch(
          `https://api.open-meteo.com/v1/forecast?latitude=${lat}&longitude=${lon}&daily=temperature_2m_min,temperature_2m_max,weathercode&timezone=auto&temperature_unit=fahrenheit`
        );
        const data = await response.json();

        const today = new Date().toISOString().split("T")[0];

        if (data.daily.time[0] !== today) {
          // If the first element is NOT today's date, find today's index.
          const startIndex = data.daily.time.findIndex(
            (date) => date === today
          );

          if (startIndex !== -1) {
            // Slice the data to start from today's index.
            setWeatherData({
              time: data.daily.time.slice(startIndex),
              temperature_2m_max:
                data.daily.temperature_2m_max.slice(startIndex),
              temperature_2m_min:
                data.daily.temperature_2m_min.slice(startIndex),
              weathercode: data.daily.weathercode.slice(startIndex),
            });
          } else {
            console.error("Today's weather data not found in forecast range.");
          }
        } else {
          // If the first element IS today's date, use the data as is.
          setWeatherData({
            time: data.daily.time,
            temperature_2m_max: data.daily.temperature_2m_max,
            temperature_2m_min: data.daily.temperature_2m_min,
            weathercode: data.daily.weathercode,
          });
        }
      } catch (error) {
        console.error("Error fetching weather data:", error);
      }
    };

    fetchWeather();
  }, [lat, lon]);

  if (!weatherData) return <Typography>Loading weather...</Typography>;

  const getWeatherIcon = (weatherCode) => {
    switch (weatherCode) {
      case 1:
        return "☀️"; // Sunny
      case 2:
        return "🌥️"; // Cloudy
      case 3:
        return "🌧️"; // Rainy
      case 4:
        return "⛈️"; // Stormy
      default:
        return "☁️"; // Default Cloudy
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        alignItems: "center",
        backgroundColor: "#1f567c",
        color: "white",
        height: { xs: "auto", md: 150 },
        padding: 2,
        borderRadius: 2,
      }}
    >
      {/* Current Weather */}
      <Box
        sx={{
          width: { xs: "100%", md: 300 },
          textAlign: "center",
          marginBottom: { xs: 2, md: 0 },
        }}
      >
        <Typography variant="h6">Today's Weather</Typography>
        <Typography variant="h3" sx={{ margin: "10px 0" }}>
          {getWeatherIcon(weatherData.weathercode[0])}
        </Typography>
        <Typography variant="body1">
          {Math.round(weatherData.temperature_2m_max[0])}°F /{" "}
          {Math.round(weatherData.temperature_2m_min[0])}°F
        </Typography>
      </Box>

      {/* Weekly Forecast */}
      <Grid
        container
        sx={{
          flex: 1,
          gap: 1,
          justifyContent: "center",
          flexWrap: "nowrap", // Prevent wrapping
          overflowX: "auto", // Enable horizontal scrolling
        }}
      >
        {weatherData.time.map((day, index) => (
          <Grid
            item
            xs={12 / 7}
            key={index}
            sx={{
              textAlign: "center",
              padding: 1,
              borderRadius: 1,
              backgroundColor: index % 2 === 0 ? "#24597f" : "#1f567c",
            }}
          >
            <Typography variant="subtitle2">
              {day.slice(0, 10) &&
                new Date(day.slice(0, 10) + "T00:00").toLocaleDateString(
                  "en-US",
                  { weekday: "short" }
                )}
            </Typography>
            <Typography>
              {getWeatherIcon(weatherData.weathercode[index])}
            </Typography>
            <Typography variant="body2">
              {Math.round(weatherData.temperature_2m_max[index])}°F /{" "}
              {Math.round(weatherData.temperature_2m_min[index])}°F
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Weather;
