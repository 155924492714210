import React, { useCallback, useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  Skeleton,
  FormControlLabel,
  Button,
  Tabs,
  Tab,
  TablePagination,
} from "@mui/material";
import Link from "next/link";
import {
  Attribute,
  AttributeLabel,
  AttributeValue,
} from "@/components/Attribute";
import moment from "moment";
import { useRouter } from "next/router";
import LocationOnIcon from "@mui/icons-material/LocationOn";

import { useAppState } from "@/appContext";
import { checkEventStatus, getAttributeValue } from "@/utils/utility";
import DateTimeDisplay from "@/components/Elements/DateTimeDisplay";
import { useTheme } from "@mui/styles";
import { CustomSwitchControl } from "@/components/Elements/Switch/SwitchControl";
import MapIcon from "@mui/icons-material/Map";
import ViewListIcon from "@mui/icons-material/ViewList"; // Icon for accordion view
import MyGoogleMap from "@/components/Elements/GoogleMap";
import MusicNoteIcon from "@mui/icons-material/MusicNote";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import useIsSmallScreen from "@/utils/useIsSmallScreen";
import FacebookTwoToneIcon from "@mui/icons-material/FacebookTwoTone";
import Tooltip from "@mui/material/Tooltip";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";

export const EventImage = ({ imgUrl, styles }) => {
  const fallBackUrl = "/images/random/defaultband.jpg";
  const [url, setUrl] = useState(imgUrl);
  const handleImageError = () => {
    setUrl(fallBackUrl);
  };
  return (
    <img
      src={url}
      alt={`event images`}
      onError={handleImageError}
      style={{ ...styles }}
    />
  );
};

const attributes = [
  {
    label: "Title",
    name: "title",
    sortable: true,
  },
  {
    label: "Description",
    name: "eventdescription",
    component: {
      type: "html",
    },
  },
  {
    label: "Event Date",
    name: "main_date",
    sortable: true,
    component: {
      type: "dateTime",
    },
  },
  {
    label: "Bands",
    name: "bands",
    component: {
      type: "object_array",
      contextProp: "bandname",
      queryParam: "internallink",
      path: "bands",
    },
  },
  {
    label: "Venue Name",
    name: "venue_name",
    // state: "venues",
    component: {
      type: "link",
      queryParam: "venue_internallink",
      identifier: "venue",
      path: "venues",
    },
  },
  {
    label: "Buy Tickets",
    name: "ticket_url",
    component: {
      type: "anchor",
    },
  },
];
const ITEMS_PER_PAGE = [20, 50, 100, 200, 500];
const EventAccordion = ({
  data,
  showIndicators = false,
  setPaginationProps,
  totalCount,
  loading,
  currentPage,
  setCurrentPage,
  showImage = true,
  showCompactDesign = false,
  isMainView = true,
  ignoreFilters,
  setIgnoreFilters,
  defaultItemsPerPage = 20,
  type = "",
  ...props
}) => {
  const [itemsPerPage, setItemsPerPage] = useState(
    type === "venue" ? 100 : defaultItemsPerPage
  );
  const currentItems = data;
  const [showImages, setShowImages] = useState(showImage);
  const [compactDesign, setCompactDesign] = useState(showCompactDesign);
  const [viewMode, setViewMode] = useState("list");
  // const [currentItems, setCurrentItems] = useState([]);
  const [state, dispatch] = useAppState();
  const handleChangePage = (event, value) => {
    setCurrentPage(value);
  };
  const isSmallScreen = useIsSmallScreen();
  const handleChangeItemsPerPage = (event) => {
    setItemsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };
  useEffect(() => {
    if (setPaginationProps) {
      setPaginationProps(currentPage * itemsPerPage, itemsPerPage);
    }
  }, [currentPage, itemsPerPage]);
  const theme = useTheme();
  const router = useRouter();
  const handleViewDetails = useCallback(
    (e, id) => {
      e.preventDefault(); // Prevent default link behavior
      e.stopPropagation();

      const { eventId } = router.query;
      // Use router to navigate to the desired page with query params
      router.push(`/events/eventId=${id}`);
    },
    [router]
  );

  const getImageUrlForBand = (bands) => {
    const bandWithImage = bands?.find(
      (band) => band.imageUrl && band.imageUrl.length > 0
    );
    // Return the img_preview if found, or undefined if no such band exists
    return bandWithImage && bandWithImage.imageUrl
      ? bandWithImage.imageUrl
      : "/images/random/defaultband.jpg";
  };

  const [expanded, setExpanded] = useState(false);

  return (
    <>
      {loading ? (
        <Box width={"100%"}>
          <Skeleton height={100} animation="wave" />
          <Skeleton height={100} animation="wave" />
          <Skeleton height={100} animation="wave" />
          <Skeleton height={100} animation="wave" />
          <Skeleton height={100} animation="wave" />
        </Box>
      ) : (
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: { xs: "column", sm: "column", md: "row" },
              gap: { xs: "10px", sm: "10px" },
              mt: { xs: "20px", sm: "20px" },
            }}
          >
            {/* <ConvertCollectionButton /> */}
            {viewMode === "list" ? (
              <Box sx={{ display: "flex" }}>
                <FormControlLabel
                  control={
                    <CustomSwitchControl
                      onChange={() => setShowImages((p) => !p)}
                      checked={showImages}
                      color="secondary"
                      sx={{ m: 1, mt: 0 }}
                    />
                  }
                  sx={{
                    "& .MuiTypography-root": {
                      fontSize: "14px",
                      fontWeight: "bold",
                      mb: 1,
                    },
                  }}
                  label="Show Images"
                />
                <FormControlLabel
                  control={
                    <CustomSwitchControl
                      onChange={() => setCompactDesign((p) => !p)}
                      checked={compactDesign}
                      color="secondary"
                      sx={{ m: 1, mt: 0, ml: 2 }}
                    />
                  }
                  sx={{
                    "& .MuiTypography-root": {
                      fontSize: "14px",
                      fontWeight: "bold",
                      mb: 1,
                    },
                  }}
                  label="Compact Design"
                />
                {isMainView && setIgnoreFilters && (
                  <FormControlLabel
                    control={
                      <CustomSwitchControl
                        onChange={() => setIgnoreFilters((p) => !p)}
                        checked={ignoreFilters}
                        color="secondary"
                        sx={{ m: 1, mt: 0, ml: 2 }}
                      />
                    }
                    sx={{
                      "& .MuiTypography-root": {
                        fontSize: "14px",
                        fontWeight: "bold",
                        mb: 1,
                      },
                    }}
                    label="Ignore Filters"
                  />
                )}
              </Box>
            ) : (
              <Box sx={{ display: "flex" }}>
                {isMainView && setIgnoreFilters && (
                  <FormControlLabel
                    control={
                      <CustomSwitchControl
                        onChange={() => setIgnoreFilters((p) => !p)}
                        checked={ignoreFilters}
                        color="secondary"
                        sx={{ m: 1, mt: 0, ml: 2 }}
                      />
                    }
                    sx={{
                      "& .MuiTypography-root": {
                        fontSize: "14px",
                        fontWeight: "bold",
                        mb: 1,
                      },
                    }}
                    label="Ignore Filters"
                  />
                )}
              </Box>
            )}

            {isMainView && false && (
              <Box
                sx={{
                  display: "flex",
                  bgcolor: "#fff",
                  // mb: "4px",
                  borderRadius: "4px",
                  justifyContent: { xs: "center", sm: "center", md: "left" },
                }}
              >
                <Tabs
                  onChange={(e, v) => setViewMode(v)}
                  sx={{
                    "& .MuiTab-root": {
                      color: "#afafaf", // Example: a green color
                    },
                    // Customizing the indicator (underline) color
                    "& .MuiTabs-indicator": {
                      backgroundColor: theme?.primaryColor, // Example: a deep orange color
                    },
                    "& .Mui-selected": {
                      color: theme?.primaryColor, // Example: a green color
                    },
                  }}
                  value={viewMode}
                >
                  <Tab
                    // label={"List View"}
                    icon={<ViewListIcon />}
                    value={"list"}
                    label={"List View"}

                  // iconPosition="start"
                  ></Tab>

                  <Tab
                    // label={"Map View"}
                    icon={<MapIcon />}
                    value={"map"}
                    label={"Map View"}
                  // iconPosition="start"
                  ></Tab>
                </Tabs>
              </Box>
            )}
            {true ? (
              <Box
                sx={{
                  display: { xs: "flex", sm: "flex", md: "block" },
                  justifyContent: "end",
                }}
              >
                <Typography sx={{ color: "#8a8787", mt: "10px" }}>
                  {`Showing ${totalCount} Events in ${Math.ceil(
                    totalCount / itemsPerPage
                  )} Page(s)`}
                </Typography>
              </Box>
            ) : (
              <Box>
                <Typography sx={{ color: "#8a8787", mt: "10px" }}>
                  {`Total: ${totalCount}`}
                </Typography>
              </Box>
            )}
          </Box>

          {viewMode === "list" ? (
            currentItems.map((event, index) => (
              <Accordion
                slotProps={{ transition: { unmountOnExit: true } }}
                key={+index + `${event.id + event.title}`}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                    background:
                      moment(event?.main_date).isBefore(
                        new Date(new Date().setHours(new Date().getHours() - 4))
                      ) && "#e2e2e2",
                  }}
                >
                  {checkEventStatus(event?.main_date) && (
                    <Box
                      sx={{
                        position: "absolute",
                        bottom: 5,
                        left: "50%",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "red",
                          fontSize: "16px",
                          fontWeight: "bold",
                          fontStyle: "italic",
                        }}
                      >
                        {checkEventStatus(event?.main_date)}
                      </Typography>
                    </Box>
                  )}
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      alignItems: "center",
                      justifyContent: {
                        xs: "space-between",
                        sm: "space-between",
                        md: "normal",
                      },
                      flexWrap: { xs: "wrap", sm: "wrap" }, // Ensures that content wraps on smaller screens
                    }}
                  >
                    {/* Image and Date Section */}
                    <Box
                      key={"events-images " + index + event?.id + event.title}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        width: { xs: "100%", sm: "100%", md: "auto" }, // Full width on small screens, auto on larger
                        marginBottom: { xs: "8px", sm: "8px", md: "0" }, // Adds spacing on smaller screens
                      }}
                    >
                      {showImages && (
                        <EventImage
                          styles={{
                            alignSelf: "center",
                            // height: event?.imageUrl
                            //   ? "20px"
                            //   : compactDesign
                            //   ? "30px"
                            //   : "80px",
                            maxHeight: compactDesign ? "30px" : "80px",

                            marginRight: "20px",
                            width: compactDesign ? "30px" : "80px",
                          }}
                          imgUrl={
                            event?.imageUrl
                              ? event?.imageUrl
                              : event.bands.length
                                ? getImageUrlForBand(event?.bands)
                                : "/images/random/defaultband.png"
                          }
                        />
                      )}
                      <Box
                        sx={{
                          marginRight: { xs: 2, sm: 2, md: 4 },
                        }}
                      >
                        <DateTimeDisplay
                          compactDesign={compactDesign}
                          isoDate={event?.main_date}
                        />
                      </Box>
                    </Box>

                    {/* Event and Venue Details Section */}
                    <Box sx={{ width: "75%", flexGrow: 1 }}>
                      <Box
                        sx={{
                          display: { xs: "block", sm: "block", md: "flex" },
                        }}
                      >
                        <Typography
                          variant="h6"
                          component="div"
                          sx={{
                            fontWeight: "bold",
                            color: theme?.fontHeading,
                            overflow: "hidden",
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: 2,
                            whiteSpace: "normal",
                            wordWrap: "break-word",
                          }}
                        >
                          {event.title || "Event Title"}
                          {event.eventdescription?.trim() && (
                            <span style={{ fontStyle: "italic", color: theme?.fontSubHeading }}
                              aria-label="Event has additional information"
                            >
                              <Tooltip title="Click to read more" arrow>
                                <ExpandMoreIcon
                                  sx={{
                                    ml: 1,
                                    cursor: "pointer",
                                    color: "red" || "primary",
                                    transform: expanded ? "rotate(180deg)" : "rotate(0deg)", // ✅ Flips icon
                                    transition: "transform 0.3s ease-in-out", // ✅ Smooth animation
                                  }}
                                  onClick={() => setExpanded((prev) => !prev)} // ✅ Toggles state
                                />
                              </Tooltip>
                            </span>
                          )}

                        </Typography>

                        <Box>
                          {event?.ticket_url && (
                            <span
                              style={{
                                marginLeft: "10px",
                                fontSize: "12px",
                                textDecoration: "none",
                                color: "#0a5029",
                              }}
                            >
                              <a
                                href={event?.ticket_url}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ textDecoration: "none" }}
                              >


                                <ConfirmationNumberIcon
                                  sx={{ color: "grey" || "primary.main" }}
                                  aria-label="Buy Tickets (Opens in new Tab)"
                                />
                              </a>
                            </span>
                          )}
                          {event?.fbid && (
                            <span
                              style={{
                                marginLeft: "10px",
                                textDecoration: "none",
                              }}
                            >
                              <a
                                href={`https://www.facebook.com/events/${event?.fbid}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ textDecoration: "none" }}
                              >
                                <FacebookTwoToneIcon
                                  sx={{
                                    height: "25px",
                                    marginBottom: "-2px",
                                    color: "blue",
                                  }}
                                />
                              </a>
                            </span>
                          )}
                        </Box>
                      </Box>
                      {!compactDesign && event.bands.length > 0 && (
                        <Typography
                          variant="body2"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            color: theme?.fontSubHeading,
                            marginTop: { xs: 1, sm: 1, md: 0 }, // Adds spacing on smaller screens
                          }}
                        >
                          <MusicNoteIcon
                            sx={{ mr: 0.5, height: "16px", color: "red" }}
                          />

                          {event.bands.map((band) => band.bandname).join(", ")}
                        </Typography>
                      )}
                      <Typography
                        variant="body2"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          color: theme?.fontSubHeading,
                          marginTop: { xs: 1, sm: 1, md: 0 }, // Adds spacing on smaller screens
                        }}
                      >
                        <a
                          href={`http://maps.apple.com/?q=${encodeURIComponent(
                            event?.address
                          )}+${encodeURIComponent(
                            event?.city
                          )},+${encodeURIComponent(event?.venue_state)}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src="/applemap.jpg"
                            alt="Apple/Google Map"
                            style={{ width: "12px", marginRight: "10px" }}
                          />
                        </a>
                        <Typography
                          component="span"
                          sx={{ fontWeight: "bold", color: "black" }}
                        >
                          {event.venue_name}
                        </Typography>
                        &nbsp;
                        {` ${event.address} ${event.city}, ${event.venue_state} `}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: {
                          xs: "column",
                          sm: "column",
                          md: "row",
                        },
                        marginTop: { xs: 2, sm: 2, md: 0 }, // Adds spacing on smaller screens
                      }}
                    >
                      <Link
                        href={{
                          pathname: `/events/${event.id}`,
                          query: { from: router.asPath },
                        }}
                      >
                        <Button
                          sx={{
                            background: "#334e65",
                            color: "white",
                            textTransform: "none",
                            fontSize: "13px",
                            padding: "8px 16px",
                            mr: 3,
                            borderRadius: "4px",
                            "&:hover": {
                              background: "#446b87",
                            },
                          }}
                        >
                          Details
                        </Button>
                      </Link>

                    </Box>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  {attributes.map((attribute, idx) => (
                    <Attribute key={idx + attribute.name + event?.id}>
                      <AttributeLabel>{attribute.label}</AttributeLabel>
                      <AttributeValue>
                        {attribute?.component
                          ? getAttributeValue(attribute, event, state)
                          : event[attribute.name]}
                      </AttributeValue>
                    </Attribute>
                  ))}
                </AccordionDetails>
              </Accordion>
            ))
          ) : (
            <Box>
              {currentItems.length > 0 && (
                <MyGoogleMap
                  eventView={true}
                  height="600px"
                  showDistance={false}
                  markers={currentItems.map((v) => ({
                    ...v,
                    description: v.eventdescription,
                    address1: v.address,
                    venue_city: v.city,
                    VENUE_ZIP: v.zip,
                  }))}
                />
              )}
            </Box>
          )}
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              p: 2,
              pt: 4,
            }}
          >
            {totalCount > itemsPerPage && setPaginationProps && (
              <TablePagination
                component="div"
                count={totalCount}
                page={currentPage}
                onPageChange={handleChangePage}
                rowsPerPage={itemsPerPage}
                onRowsPerPageChange={handleChangeItemsPerPage}
                rowsPerPageOptions={ITEMS_PER_PAGE}
              />
            )}
          </Box>
        </Box>
      )}
    </>
  );
};

export default EventAccordion;
