import { FC } from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";

const StyledDiv = styled("div")({
  fontSize: "14px",
  lineHeight: 1.33,
  color: "#9f9c9c",
  minWidth: "120px",
  display: "block",
  wordBreak: "break-word",
  padding: "6px 10px",
  fontWeight: "bold",
});
export const AttributeLabel = (props) => {
  return (
    <StyledDiv
      sx={{
        ...props.styles,
      }}
    >
      {props.children}
    </StyledDiv>
  );
};
