import { styled } from "@mui/system";
import { isNilOrEmpty } from "../../utils";
import React from "react";

const StyledDiv = styled("div")({
  fontSize: "14px",
  lineHeight: 1.33,
  color: "black",
  width: "100%",
  display: "block",
  wordBreak: "break-word",
  wordWrap: "break-word",
  padding: "6px 10px",
});
export const AttributeValue = (props) => {
  return (
    <StyledDiv
      sx={{
        ...props.styles,
      }}
    >
      {isNilOrEmpty(props.children) ? "-" : props.children}
    </StyledDiv>
  );
};
